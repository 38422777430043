// import Vue from "vue"
import { createRouter, createWebHashHistory } from "vue-router"
// import CalendarGroups from "./CalendarGroups.vue"
import AvailableScheduleView from "./AvailableScheduleView.vue"
import ClientAvailableScheduleView from "packs/pages/link/ClientAvailableScheduleView.vue"
import Rooms from "./Rooms.vue"
import RoomSettings from "./RoomSettings.vue"
import Gate from "./Gate.vue"
// import DevMenu from "../developer/Menu.vue"
import Account from "./settings/Account.vue"
import AccountSettings from "./settings/AccountSettings.vue"
import AccountSettingsNotifications from "./settings/AccountSettingsNotifications.vue"
import AccountSettingsSecurity from "./settings/AccountSettingsSecurity.vue"
import AccountSettingsSaml from "./settings/AccountSettingsSaml.vue"
import UserSettingsInfo from "./settings/UserSettingsInfo.vue"
import UserSettingsAddressBook from "./settings/UserSettingsAddressBook.vue"
import UserSettingsNotification from "./settings/UserSettingsNotification.vue"
import UserSettingsCustomNotifications from "./settings/UserSettingsCustomNotifications.vue"
import UserSettingsSchedule from "./settings/UserSettingsSchedule.vue"
import UserSettingsDownload from "./settings/UserSettingsDownload.vue"
import UserSettingsMail from "./settings/UserSettingsMail.vue"
import UserSettingsShareCalendars from "./settings/UserSettingsShareCalendars.vue"
import UserSettingsRooms from "./settings/UserSettingsRooms.vue"
import Forms from "./settings/Forms.vue"
import Payment from "./settings/Payment.vue"
import Summary from "./RoomSummary.vue"
import ScheduleView from "../schedule/ScheduleView.vue"
import Reports from "./Reports.vue"
import Progresses from "./Progresses.vue"
import GoogleFormSettings from "./settings/GoogleFormSettings.vue"
import UserSettingsLocations from "./settings/UserSettingsLocations.vue"
import InstantRoomManagement from "./parts/instant_room_management/InstantRoomManagement.vue"
import NotInvited from "../common/NotInvited.vue"
import DisconnectCalendar from "../common/DisconnectCalendar.vue"
import AssignForms from "./settings/AssignForms.vue"
// Vue.use(Router)

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            name: "root",
            component: Rooms,
        },
        {
            path: "/available_schedule",
            name: "AvailableScheduleView",
            component: AvailableScheduleView,
        },
        {
            path: "/client/available_schedule",
            name: "ClientAvailableScheduleView",
            component: ClientAvailableScheduleView,
        },
        {
            path: "/room_settings",
            name: "RoomSettings",
            component: RoomSettings,
        },
        {
            path: "/gate",
            name: "Gate",
            component: Gate,
        },
        {
            path: "/account",
            name: "Account",
            component: Account,
        },
        {
            path: "/account_settings",
            name: "AccountSettings",
            component: AccountSettings,
        },
        {
            path: "/account_settings/notifications",
            name: "AccountSettingsNotifications",
            component: AccountSettingsNotifications,
        },
        {
            path: "/account_settings/security",
            name: "AccountSettingsSecurity",
            component: AccountSettingsSecurity,
        },
        {
            path: "/account_settings/saml",
            name: "AccountSettingsSaml",
            component: AccountSettingsSaml,
        },
        {
            path: "/payment",
            name: "Payment",
            component: Payment,
        },
        {
            path: "/summary",
            name: "Summary",
            component: Summary,
        },
        {
            path: "/pub_summary",
            name: "PubSummary",
            component: Summary,
        },
        {
            path: "/schedule",
            name: "ScheduleView",
            component: ScheduleView,
        },
        {
            path: "/schedule_preview",
            name: "ScheduleViewPreview",
            component: ScheduleView,
        },
        {
            path: "/p/schedule_preview",
            name: "ScheduleViewPreviewPublic",
            component: ScheduleView,
        },
        {
            path: "/settings/schedule",
            name: "UserSettingsSchedule",
            component: UserSettingsSchedule,
        },
        {
            path: "/settings/share_calendars",
            name: "UserSettingsShareCalendars",
            component: UserSettingsShareCalendars,
        },
        {
            path: "/settings/rooms/:tab?",
            name: "UserSettingsRooms",
            component: UserSettingsRooms,
            props: true,
        },
        {
            path: "/settings/locations",
            name: "UserSettingsLocations",
            component: UserSettingsRooms,
        },
        {
            path: "/settings",
            name: "UserSettingsInfo1",
            component: UserSettingsInfo,
        },
        {
            path: "/settings/user",
            name: "UserSettingsInfo2",
            component: UserSettingsInfo,
        },
        {
            path: "/settings/address_book",
            name: "UserSettingsAddressBook",
            component: UserSettingsAddressBook,
        },
        {
            path: "/settings/notification",
            name: "UserSettingsNotification",
            component: UserSettingsNotification,
        },
        {
            path: "/settings/notifications",
            name: "UserSettingsCustomNotifications",
            component: UserSettingsCustomNotifications,
        },
        {
            path: "/settings/mail/:tab?",
            name: "UserSettingsMail",
            component: UserSettingsMail,
            props: true,
        },
        {
            path: "/settings/download",
            name: "UserSettingsDownload",
            component: UserSettingsDownload,
        },
        {
            path: "/settings/forms",
            name: "Forms",
            component: Forms,
        },
        {
            path: "/form_settings",
            name: "GoogleFormSettings",
            component: GoogleFormSettings,
        },
        {
            path: "/instant_rooms",
            name: "InstantRooms",
            component: InstantRoomManagement,
        },
        {
            path: "/reports",
            name: "Reports",
            component: Reports,
        },
        {
            path: "/assign_forms",
            name: "AssignForms",
            component: AssignForms,
        },
        {
            path: "/progresses",
            name: "Progresses",
            component: Progresses,
        },
        {
            path: "/not_invited",
            name: "NotInvited",
            component: NotInvited,
        },
        {
            path: "/disconnect_calendar",
            name: "DisconnectCalendar",
            component: DisconnectCalendar,
        },
        {
            path: "/:pathMatch(.*)*",
            name: "root2",
            component: Rooms,
            beforeEnter: (to, from, next) => {
                // リダイレクト処理.
                next(`/`)
            },
        },
    ],
})
