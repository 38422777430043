import axios from "axios"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
// import qs from "qs"
// import isMobile from "ismobilejs"
import Appointment from "packs/models/Appointment"
import Util from "./Util"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import FormField from "packs/models/FormField"
import FormFieldCreator from "./FormFieldCreator"
import AppealContent from "packs/models/AppealContent"
import RoomManager from "packs/models/RoomManager"
import UserSetting from "packs/models/UserSetting"
// declare var ics: any
// const ics = require("ics")
// const { writeFileSync } = require("fs")

//  便利系を記載.
export default class RoomCreator {
    static getParentDicRooms() {
        return [
            {
                icon: `/assets/email/status0-1`,
                line: 2,
                title: `1人と日程調整したい`,
                content: `お相手1人と日程調整します。`,
                typeName: `1`,
                room: {
                    room_type: `parent_room`,
                },
            },
            {
                icon: `/assets/email/status0-1`,
                line: 2,
                title: `2人以上と日程調整したい`,
                content: `お相手複数人と日程調整します。`,
                typeName: `2`,
                room: {
                    room_type: `parent_room`,
                },
            },
            {
                icon: `/assets/email/status0-1`,
                line: 4,
                title: `自分が参加しない日程調整をしたい`,
                content: `自分が参加しないお相手複数人と日程調整します。`,
                typeName: `no_owner`,
                room: {
                    room_type: `parent_room`,
                    current_appointment: {
                        required_owner_participation: false,
                    },
                },
            },
        ]
    }

    static getParentHideDicRooms() {
        return [
            {
                icon: `/assets/email/status0-1`,
                line: 2,
                title: `CSVで一括で日程調整したい`,
                content: `CSVを読み込んで日程調整をします。`,
                typeName: `csv`,
            },
            {
                icon: `/assets/email/status0-1`,
                line: 3,
                title: `Excelで一括で日程調整したい`,
                content: `Excelを読み込んで日程調整をします。`,
                typeName: `excel`,
            },
        ]
    }

    static getVoteRoomDic(name = null): { [key: string]: any } {
        let dic = {
            icon: `/assets/icons/vote`,
            line: 2,
            title: `投票（提案型）`,
            content: `日程を提案してもらうページを作成します。`,
            typeName: `voted`,
            classes: ``,
            imageClasses: ``,
            room: {
                current_appointment: {
                    fix_type: 1,
                },
                appeal_content: {
                    title: name ? name : `日程投票ページ`,
                    content: `出席可能な日程をできるだけ多く選択してください。`,
                },
                room_setting: {
                    expiration_type: `permanent`,
                },
                use_chat: false,
                form_fields: [`remarks`],
                display_form_timing_type: `with_fix`,
                display_schedule_type: `anytime`,
            },
        }
        return dic
    }

    static getSimplePublicDic() {
        return {
            icon: `/assets/icons/calendar_share`,
            line: 3,
            title: `シンプル`,
            content: `予約受付できるページを作成します。`,
            typeName: `pub`,
            classes: ``,
            imageClasses: `ml15`,
            room: {
                room_type: `public_room`,
                current_appointment: {
                    fix_type: 100,
                },
                use_chat: true,
                form_fields: [`remarks`],
                display_form_timing_type: `with_fix`,
                display_schedule_type: `anytime`,
            },
        }
    }

    static getFeedbackRoomDic() {
        return {
            icon: `/assets/icons/form_green`,
            line: 3,
            title: `アンケートフォームを作成`,
            content: `アンケートフォームを作成します`,
            typeName: `feedback_form`,
            classes: ``,
            imageClasses: `ml15`,
            room: {
                current_appointment: {
                    fix_type: 100,
                },
                appeal_content: {
                    title: `アンケートフォーム`,
                    content: `以下アンケートにお答えください。`,
                    image_url: `/assets/header_samples/21.png`,
                },
                use_chat: false,
                form_fields: [`satisfaction`, `satisfactionReason`, `useRepeat`, `request`, `nextSchedule`],
                rule: [`wantNextSchedule`],
                display_form_timing_type: `open_page`,
                display_schedule_type: `rules`,
                add_event_type: `same_event_but_not_display_form`,
            },
        }
    }

    static getPublicDicRooms() {
        return [this.getSimplePublicDic(), this.getVoteRoomDic(), this.getFeedbackRoomDic()]
    }

    static getPublicHideDicRooms() {
        return [
            {
                icon: `/assets/email/form_icon_green`,
                line: 4,
                title: `セミナーを開催したい`,
                content: `セミナー開催日を選択可能なページを作成します`,
                typeName: `seminer`,
                room: {
                    current_appointment: {
                        fix_type: 100,
                    },
                    appeal_content: {
                        title: `ウェビナー開催`,
                        content: `表示されています日程から参加可能な日程をご選択ください。\nウェビナーの会議URLは、確定しましたら参加者にお送りいたします。\n\n<講義内容>\n■ これからの日程調整について(60分)\n\n<こんな方にお勧めです>\n日程調整サービスをより効率的にお使いいただける方法をお伝えします。`,
                        image_url: `/assets/header_samples/21.png`,
                    },
                    use_chat: false,
                    form_fields: [`remarks`],
                    display_form_timing_type: `with_fix`,
                    display_schedule_type: `anytime`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                line: 4,
                title: `日程調整付きのお問い合わせフォームを公開したい`,
                content: `日程調整まで行うお問い合わせフォームを作成します。`,
                typeName: `open_page`,
                room: {
                    appeal_content: {
                        title: `お問い合わせフォーム`,
                        content: `下記お問い合わせ内容にご記入いただき、送信してください。\nお問い合わせの内容によっては、お時間を頂戴する場合がございます。\n提案やご紹介のメールに対しましては、お返事を差し上げられない場合がございます。`,
                    },
                    use_chat: true,
                    form_fields: [`phone`, `content`],
                    display_form_timing_type: `open_page`,
                    display_schedule_type: `anytime`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                line: 5,
                title: `日程調整付きのお問い合わせフォームを公開したい（ルールあり）`,
                content: `条件付きで日程調整を行うお問い合わせフォームを作成します。`,
                typeName: `open_page_with_rule`,
                room: {
                    appeal_content: {
                        title: `お問い合わせフォーム`,
                        content: `下記お問い合わせ内容にご記入いただき、送信してください。\nお問い合わせの内容によっては、お時間を頂戴する場合がございます。\n提案やご紹介のメールに対しましては、お返事を差し上げられない場合がございます。`,
                    },
                    use_chat: true,
                    form_fields: [`phone`, `content`, `wantOnlineMeeting`],
                    rule: [`wantOnlineMeeting`],
                    display_form_timing_type: `open_page`,
                    display_schedule_type: `rules`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                line: 4,
                title: `個別相談会ページを公開したい`,
                content: `日程調整付きのお問い合わせフォームを作成します。`,
                typeName: `consultation`,
                room: {
                    appeal_content: {
                        title: `個別相談会（予約制）`,
                        content: `下記フォームより日時を予約をしてください。\nお電話での予約はできませんので、ご了承ください。`,
                    },
                    use_chat: true,
                    form_fields: [`phone`, `question`],
                    display_form_timing_type: `with_fix`,
                    display_schedule_type: `never`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                title: `人事面談1:1ページを公開したい`,
                line: 4,
                content: `面談相手が投票形式でそれぞれ日程を選びます。IPアドレス制限が可能です。`,
                typeName: `human`,
                room: {
                    current_appointment: {
                        fix_type: 1,
                    },
                    appeal_content: {
                        title: `人事面談 予約受付ページ`,
                        content: `期末のフィードバック面談を次のような手順で進めます。\n\n面談場所についてはZoomで行います。\n\n面談時間については一人につき30分程度は確保します。\n\n事前に人事部に伝えておきたい場合は、フォームにあります備考欄にご記入ください。`,
                    },
                    use_chat: false,
                    form_fields: [`companyInfoGroup`, `remarks`],
                    display_form_timing_type: `with_fix`,
                    display_schedule_type: `anytime`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                line: 4,
                title: `日程調整付きの新卒・中途採用応募フォームを公開したい`,
                content: `日程調整つきの応募フォームを作成します。`,
                typeName: `adoption`,
                room: {
                    current_appointment: {
                        fix_type: 1,
                    },
                    appeal_content: {
                        title: `新卒採用応募フォーム`,
                        content: `下記フォームより、エントリーを受け付けております。\n入力の際には、必須の付いている項目は必ず入力いただきますようお願いいたします。`,
                    },
                    use_chat: false,
                    form_fields: [`希望職種 checkbox`, `gender`, `birthday`, `addressGroup`, `phone`, `univGroup`],
                    display_form_timing_type: `open_page`,
                    display_schedule_type: `anytime`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                line: 4,
                title: `アフターフォローで利用したい`,
                content: `日程調整つきのアフターフォロー用フォームを作成します。`,
                typeName: `after_follow`,
                room: {
                    current_appointment: {
                        fix_type: 1,
                    },
                    appeal_content: {
                        title: `アフターフォロー`,
                        content: `こちらでは、既にサービスをご利用された方からの、お問い合わせをお受けしております。\nご連絡可能な日程を複数お選びください。\n下のフォームにご記入の上、ご送信ください。弊社で内容を確認のうえ、担当者からご連絡いたします。`,
                    },
                    use_chat: false,
                    form_fields: [`serviceName`, `serviceStart`, `servicePic`, `content`, `phone`, `addressGroup`],
                    display_form_timing_type: `with_fix`,
                    display_schedule_type: `never`,
                },
            },
            {
                icon: `/assets/email/form_icon_green`,
                line: 4,
                title: `みんなOKな日程を投票で決めたい（社内・セキュア）`,
                content: `IPアドレス制限をかけて○×で出欠確認できる日程調整を作成します。`,
                typeName: `voted_with_ipaddress`,
                room: {
                    current_appointment: {
                        fix_type: 1,
                    },
                    appeal_content: {
                        title: `日程投票ページ`,
                        content: `出席可能な日程をできるだけ多く選択してください。`,
                    },
                    room_setting: {
                        expiration_type: `permanent`,
                    },
                    use_chat: false,
                    form_fields: [`remarks`],
                    display_form_timing_type: `with_fix`,
                    display_schedule_type: `anytime`,
                },
            },
        ]
    }

    static configurePublicRoom(room: Room, roomDic: any, rm: typeof RoomManager) {
        let appo = room.current_appointment

        let roomDicAppo = roomDic.room.current_appointment
        if (roomDicAppo) {
            appo.fix_type = roomDicAppo.fix_type ? roomDicAppo.fix_type : 100
        } else {
            appo.fix_type = 100
        }
        Logger(`appo FixTypeを確認: ${Util.output(roomDicAppo)} ${appo.fix_type}`)
        let title = null
        let content = null
        let imageUrl = null
        if (roomDic.typeName != `pub`) {
            let apcDic = roomDic.room.appeal_content
            title = apcDic.title
            content = apcDic.content
            if (apcDic.image_url) {
                imageUrl = apcDic.image_url
            }
        }

        // display_form_timing_type
        if (roomDic.room.display_form_timing_type) {
            room.display_form_timing_type = roomDic.room.display_form_timing_type
        }

        // フォーム関係
        let ffs = FormField.createDefaultFields()
        if ([`voted_with_ipaddress`, `human`].includes(roomDic.typeName)) {
            ffs = ffs.filter(f => f.field_name != `会社名`)
        }
        let ffsArray = roomDic.room.form_fields
        Logger(`ffsArray: ${ffsArray}`)
        room.form_fields = FormFieldCreator.createFormFieldsFromArray(ffs, ffsArray)

        // display_schedule_type
        let displayType = roomDic.room.display_schedule_type
        if (displayType) {
            room.display_schedule_type = displayType
            if (displayType == `rules`) {
                // ルールを作成.
                let ruleString = roomDic.room.rule
                if (Util.isPresent(ruleString)) {
                    if (ruleString == `wantOnlineMeeting`) {
                        let rule = FormFieldCreator.wantOnlineMeetingRule()
                        room.rule = rule
                    } else if (ruleString == `wantNextSchedule`) {
                        let rule = FormFieldCreator.wantNextScheduleRule()
                        room.rule = rule
                    }
                }
            }
        }

        room.appeal_content = AppealContent.setRandomContent(rm.userInfo, title, content)
        if (Util.isPresent(imageUrl)) {
            room.appeal_content.image_url = imageUrl
        }

        appo.duration = rm.user_setting.default_duration

        room = this.setRoomSetting(room, roomDic, rm)
        room.owner = rm.userInfo

        if (Util.isPresent(rm.default_location)) {
            appo = this.setLocation(appo, rm)
        }

        room.current_appointment = appo
        // Object.assign(room, roomDic.room)
        return room
    }

    static setLocation(appo: Appointment, rm: typeof RoomManager) {
        let _loctag = rm.default_location
        if (Util.isBlank(_loctag)) return appo

        appo.selected_location = _loctag
        appo.location_name = _loctag.keyword
        appo.location_tag_id = _loctag.id
        return appo
    }

    static setRoomSetting(room: Room, roomDic: any, rm: typeof RoomManager) {
        let rs = room.room_setting
        if (Util.isPresent(rm.user_setting)) {
            let us = rm.user_setting
            Object.assign(rs, UserSetting.toRoomSetting(us))
        }

        rs.use_chat = roomDic.room.use_chat ? true : false

        if (roomDic.room && roomDic.room.add_event_type) {
            rs.add_event_type = roomDic.room.add_event_type
        }

        // rs.submit_lower_limit_num = us.submit_lower_limit_num
        // rs.email_suggests_num = us.email_suggests_num

        // 個別にdicに記載がある場合は設定を上書きします.
        let roomSettingDic = roomDic.room.room_setting
        if (roomSettingDic) {
            if (roomSettingDic.expiration_type == `permanent`) {
                rs.expiration_type = `permanent`
            }
        }
        room.room_setting = rs
        return room
    }
}
