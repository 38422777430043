import axios from "axios"
import Util from "packs/utils/Util"
import MailFlow from "packs/models/MailFlow"
import Room from "packs/models/Room"
import UserSetting from "packs/models/UserSetting"
import RoomMember from "packs/models/RoomMember"
import DateTag from "packs/models/DateTag"
import UserFile from "./UserFile"
import LocationConverter from "./LocationConverter"
import CustomNotification from "./CustomNotification"

interface ThanksPage {
    title: string
    content: string
    button_link: string
    button_name: string
    redirect_url_after_send: string
}

interface ZoomSetting {
    join_before_host: boolean
    waiting_room: boolean
    alternative_hosts: string
    passcode_type: string
}

export default class RoomSetting {
    constructor(
        public id: string,
        public ip_addresses_whitelist: string[],
        public ip_addresses_blacklist: string[],

        public email_domains_whitelist: string[],
        public email_domains_blacklist: string[],

        public privacy_policy_url: string,
        public terms_of_service_url: string,

        public submit_lower_limit_num: number,
        public email_suggests_num: number,

        public thanks_page: ThanksPage,

        public mail_flows: MailFlow[],

        // astagプレビュー時に利用するフィールド.
        public duration: number,

        // every_15_min,every_30_min,hourly,odd_hourly,even_hourly,custom
        public step_size_type: string,
        /**
         * [
         *   {wday:[1, 3], available_time: {9=> [0], 10=> [30], 18=> [0, 45]}},
         *   {wday:[2], available_time: {10=> [0], 11=> [30], 19=> [0]}}
         * ]
         */
        public available_start_times: any[],
        // auto_close,permanent,custom
        public expiration_type: string,
        // all,self
        public share_type: string,

        // same_event, other_event
        public add_event_type: string,

        // キャンセルボタンをいつまで表示させるか.
        public available_cancel_min_before_appo_start_time: number,
        public available_cancel_time_at: number,
        // 別日程をリクエストボタンを表示させるか.
        public request_dates_button_type: string,

        // 確定前何分まで有効か.
        public available_fix_min_before_appo_start_time: number,

        // zoom
        public zoom_setting: ZoomSetting,

        /**
         * v8.0.0 Astagから移動してきた変数
         */
        public assign_mag_rule: string, // order, random, equal, priority
        public period_days_for_assign: number,

        public is_private: boolean,
        // none,guess_or_none,guess_or_fixed,guess_or_minimum
        public travel_time_mode: string,
        public fixed_travel_before_min: number,
        public fixed_travel_after_min: number,
        public additional_fixed_travel_min: number,
        public can_sync_travel_time_to_calendar_event: boolean,
        public sync_travel_time_event_name: string,
        // none,weekend,weekend_and_holiday,custom
        public available_period_skip_type: string,
        // [0,1,2,3,4,5,6,10] 10=holiday
        public available_period_skip_custom,
        public meeting_room_search_keys,
        public online_meeting_resources: any[],
        public available_days_of_weeks: DateTag[],

        public mag_display_type: string, // display_none, display_and_selectable, display_after_fix
        public travel_time_mode_default_location_name: string, // 移行
        // 調整可能期間（相対）
        public available_period_start_min: number,
        public available_period_end_min: number,
        // 調整可能期間（絶対）
        public available_period_start_at: number,
        public available_period_end_at: number,
        // relative, absolute
        public available_period_type: string,
        // roomから移行
        public use_chat: boolean,
        public provider_event_title: string,
        public provider_event_description: string,
        // 調整相手も
        public can_change_appointment_from_other: boolean,
        public can_auto_download: boolean,
        public attachment_files: UserFile[],
        public can_change_timezone: boolean,

        // 調整ページ内に出席者を表示するか.
        public mask_members_info: boolean,
        public google_analytics_id: string,
        public ga_cross_domains: string,

        // これがスグINボタンか.
        public is_instant_room: boolean,
        // スグINボタンを設置するか.その下のID(public_room_id)とセットで.
        public use_instant_room: boolean,
        public instant_room_id: string,

        // スグINボタンの場合にのみ利用.
        public instant_room_description,
        public instant_room_button_title,
        public instant_room_button_small_text,

        // 投票状況を表示するか.
        public show_vote_type: string,
        // 投票状況をフィルタリングするか.
        public show_filter_vote_type: string,

        // 送信先を選択
        public send_channel_type: string,

        //
        public timezone_value: string,

        // メールの送信先を指定するか.
        public instead_of_owner_email: string,

        // 公開期限を設定するか.
        public open_period_type: string,
        public open_period_start_at: number,
        public open_period_end_at: number,

        public location_converters: LocationConverter[],
        public use_location_converters: boolean,
        public custom_notifications: CustomNotification[],
        public form_submit_button_name: string,
        public auto_share_my_room_type: string,
        public auto_share_public_id: string,
        public additional_mail_subject_type_for_owner: string,
        public additional_mail_subject_for_owner: string,

        // カレンダー表示タイプ
        public calendar_show_type: string,
        // 「可能な日程をできるだけ多く選択してください。」の文言を自由に設定できます.
        public vote_warning_text: string,
        public max_bookings_show_type: string,
        public design_type: string,
        public use_business_day: boolean,
        public countable_business_day_wdays: number[], // 営業日としてカウントする曜日のArray
        public is_count_business_day_holiday: boolean, // 祝日も営業日としてカウントするか.
        public select_time_text: string
    ) {}

    static createDefault(us: UserSetting = null): RoomSetting {
        let step = null
        let limitNum = null
        let suggestNum = null
        let stepType = null
        let startTimes = null
        let addEventType = `same_event`
        if (Util.isPresent(us)) {
            stepType = us.step_size_type || `hourly`
            limitNum = us.submit_lower_limit_num
            suggestNum = us.email_suggests_num
            startTimes = us.available_start_times
            addEventType = us.add_event_type
        }

        let thanks = this.createThanksPage()
        let mfs = MailFlow.createMailFlows()
        let zs = this.createZoomSetting(us ? us.zoom_setting : null)
        return new RoomSetting(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            limitNum,
            suggestNum,
            thanks,
            mfs,
            null,
            stepType,
            startTimes,
            `auto_close`,
            `all`,
            addEventType,
            null,
            null,
            `display`,
            null,
            zs,
            `order`, // order, random, equal, priority
            30,

            false,
            // none,guess_or_none,guess_or_fixed,guess_or_minimum
            `none`,
            null,
            null,
            null,
            false,
            null,
            // none,weekend,weekend_and_holiday,custom
            `weekend_and_holiday`,
            // [0,1,2,3,4,5,6,10] 10=holiday
            null,
            null,
            null,
            [],
            `display_none`, // display_none, display_and_selectable, display_after_fix
            null,
            // 調整可能期間（相対）
            60 * 12,
            60 * 24 * 10,
            // 調整可能期間（絶対）
            null,
            null,
            // relative, absolute
            `relative`,
            true,
            null,
            null,
            true,
            false,
            [],
            false,
            false,
            // GA設定
            null,
            null,
            false,
            false,
            null,
            null,
            null,
            null,
            `hide_all`,
            `none`,
            `email`,
            null,
            null,
            `none`,
            null,
            null,
            null,
            false,
            null,
            null,
            `none`,
            null,
            `none`,
            null,
            `monthly_monthly`,
            null,
            `none`,
            `normal`,
            false,
            [1, 2, 3, 4, 5],
            false,
            null
        )
    }

    static createThanksPage(): ThanksPage {
        return {
            title: null,
            content: null,
            button_link: null,
            button_name: null,
            redirect_url_after_send: null,
        }
    }

    static createZoomSetting(zs: ZoomSetting): ZoomSetting {
        if (Util.isPresent(zs)) return zs

        return {
            join_before_host: true,
            waiting_room: false,
            alternative_hosts: null,
            passcode_type: `normal`,
        }
    }

    static isRedirectUrl(room: Room) {
        return this.hasRedirectUrl(room) && Util.isUrl(room.room_setting.thanks_page.redirect_url_after_send)
    }

    static hasRedirectUrl(room: Room) {
        return (
            Util.isPresent(room.room_setting) &&
            Util.isPresent(room.room_setting.thanks_page) &&
            Util.isPresent(room.room_setting.thanks_page.redirect_url_after_send)
        )
    }

    /**
     *
     * @param room
     * @param us rm.user_settingに入っているもの
     */
    static findOrCreateSetting(room: Room, us: UserSetting = null): RoomSetting {
        let setting = Util.isPresent(room.room_setting) ? { ...room.room_setting } : RoomSetting.createDefault(us)
        if (Util.isBlank(setting.thanks_page)) {
            setting.thanks_page = RoomSetting.createThanksPage()
        }
        if (Util.isBlank(setting.mail_flows)) {
            setting.mail_flows = MailFlow.createMailFlows(room.room_type)
        } else {
            // 足りないmfを作成.
            let createdDefTypes = setting.mail_flows.map(m => m.def_type)
            let needTimingsArray = Util.timingsDicArray(room.room_type)
            let mfs = setting.mail_flows
            for (let _tiDic of needTimingsArray) {
                if (createdDefTypes.includes(_tiDic.type)) continue
                mfs.push(MailFlow.createDefault(_tiDic.type))
            }
            setting.mail_flows = mfs
        }
        return setting
    }

    static redirect(url) {
        let link = document.createElement("a")
        link.href = url
        link.click()
    }

    /**
     * NONE = :none
        GUESS_OR_NONE = :guess_or_none
        GUESS_OR_FIXED = :guess_or_fixed
        guess_or_minimum
        FIXED = :fixed
     */
    static travelTimeModeOptions() {
        return [
            { mode: `none`, text: `移動考慮不要` },
            { mode: `fixed`, text: `固定時間幅を入れる` },
            { mode: `guess_or_none`, text: `移動考慮し、場所が特定できない場合は入れない` },
            { mode: `guess_or_fixed`, text: `移動考慮し、場所が特定できない場合は固定時間を入れる` },
            { mode: `guess_or_minimum`, text: `移動考慮し、最低固定時間幅と比較` },
        ]
    }

    static changeModeToText(rs: RoomSetting) {
        let mode = this.travelTimeModeOptions().find(e => e.mode == rs.travel_time_mode)
        if (mode) return mode.text
        return `移動考慮不要`
    }

    /**
     * もとのrsと比較して、spanのテキストを更新する必要があるかを検証します.
     * @param beforeRs
     * @param afterRs
     * @returns
     */
    static needUpdateSpanText(beforeRs, afterRs) {
        if (!beforeRs || !afterRs) return false

        if (beforeRs.available_period_skip_type != afterRs.available_period_skip_type) return true
        if (beforeRs.available_period_type != afterRs.available_period_type) return true
        if (beforeRs.available_period_start_min != afterRs.available_period_start_min) return true
        if (beforeRs.available_period_end_min != afterRs.available_period_end_min) return true
        if (beforeRs.available_period_start_at != afterRs.available_period_start_at) return true
        if (beforeRs.available_period_end_at != afterRs.available_period_end_at) return true
        if (beforeRs.available_period_skip_custom != afterRs.available_period_skip_custom) return true
        if (beforeRs.available_days_of_weeks != afterRs.available_days_of_weeks) return true
        if (beforeRs.custom_notifications != afterRs.custom_notifications) return false
        return false
    }
}
